.acreditaciones{
    width: 100%;
    height: auto;
    padding: 70px 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.acre{
    padding: 40px 0;
    color: rgb(255, 255, 255);
    width: 100%;
    font-size: 1.5rem;
    background: url("../../images/fondo.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.documents{
    width: 90%;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 20px;
    padding: 30px 0;
    overflow: hidden;
}
.apartados-acre{
    width: 100%;
    height: auto;
    padding: 20px;
}
.img-acre{
    width: 100%;
    height: 50px;
}
#doc1,#doc2,#doc3,#doc4,#doc5,#doc6,#doc7,#doc8,#doc9{
    width: 100%;
    height: 500px;
}
.low-vol{
    padding: 20px 0 5px 0;
    font-size: 1.8rem;
    border-bottom: 1px solid black;
}
.low{
    width: 90%;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(4,1fr);
    overflow: hidden;
}
.rota-sono{
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(2,1fr);
    overflow: hidden;
}
@media (max-width:1024px){
    .low{
        grid-template-columns: repeat(2,1fr);
    }
    #doc1,#doc2,#doc3,#doc4,#doc5,#doc6,#doc7,#doc8,#doc9{
        width: 100%;
        height: 500px;
    }
    .apartados-acre{
        padding: 20px 0;
    }
}
@media (max-width:769px){
    .documents{
        grid-template-columns: 1fr;
    }
    .low{
        grid-template-columns: 1fr;
    }
    #doc1,#doc2,#doc3,#doc4,#doc5,#doc6,#doc7,#doc8,#doc9{
        width: 100%;
        height: 500px;
    }
    .apartados-acre{
        padding: 20px 0;
    }
    .rota-sono{
        grid-template-columns: 1fr;
    }
}