.decreto{
    width: 100%;
    height: auto;
    padding: 70px 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.decre{
    padding: 40px 0;
    color: rgb(255, 255, 255);
    width: 100%;
    font-size: 1.5rem;
    background: url("../../images/fondo.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.document-decre{
    width: 60%;
    height: auto;
    display:flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    overflow: hidden;
}
.apartados-decre{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 20px;
}
#doc,#doc0{
    width: 100%;
    height: 600px;
}
.low2-vol{
    padding: 20px 0 5px 0;
    font-size: 1.8rem;
    border-bottom: 1px solid black;
}
.low2{
    width: 90%;
    display:flex;
    overflow: hidden;
}

@media (max-width:1024px){
 
    #doc,#doc0{
        width: 100%;
        height: 500px;
    }
    .apartados-decre{
        padding: 20px 0;
    }
}
@media (max-width:769px){

    #doc,#doc0{
        width: 100%;
        height: 500px;
    }

}