.cuerpo-mensajeria{
    padding-top: 80px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.contenedor-mensajeria{
    width: 90%;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cuerpo-mensajeria h1{
    padding-top: 30px;
}
form{
    display: flex;
    flex-direction: column;
    width: 50%;
    /* background:#313c4a; */
    padding: 0 20px;
    border-radius: 7px;
    /* border: 1px solid gray; */
}
form input,
textarea{
    margin: 10px 0;
    padding: 10px;
    border-radius: 3px;
    
}
.enviar{
    border-radius: 3px;
    cursor: pointer;
    background: rgb(50, 93, 146);
    color: white;
    font-size: 1rem;
}
.proximamente{
    padding: 150px 0 154px 0;
    color: green;
    font-size: 3rem;
}