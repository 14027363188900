
/* CUERPO */

.uno{
    width: 100%;
    height: auto;
    padding: 70px 0 0 0;
    background: #F2F2F2;
}
.cuerpo1{
    width: 100%;
    display: grid;
    height: 700px;
    grid-template-columns: 2fr 1fr;
    overflow: hidden;
    padding: 30px 60px;
    column-gap: 90px;
    box-sizing: border-box;
}
.info-prese{
    width:100%;
    flex-direction: column;
    display: flex;
    place-content: center;
    /* padding: 0 0 0 10%; */
 }
 .img-prese{
     width: 100%;
     height: 100%;
     background: url("../../images/nosotros.jpg");
     background-repeat: no-repeat;
     background-size: cover;
     background-position: center;
 }
 .ti-prese{
    font-size: 4rem;
    padding: 0 0 50px 0;
 }
 .p-prese{
    font-size: 1.2rem;
 }
 .ti-prese,
 .p-prese{
    text-align: justify;
 }
 .ul--{
    font-size: 1.2rem;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 20px 0 0 20px;
 }


.dos{
    background:  rgb(230, 236, 240);
    width: 100%;
    height: auto;
    display: flex;        
}
.cuerpo2{
    width: 90%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    place-items: center;
    align-items: flex-start;
    text-align: justify;
    gap: 30px;
    padding: 50px 0 80px 0px;
    overflow: hidden;
}
.nosotros1{
    grid-column: 1/3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background: #1f1f1f;    */
    /* width: 100%; */
}
.nosotros1 p{
    padding: 15px 0 20px 0;
    font-size:clamp(1.02rem, 2vw, 1.5rem);
}
.ti-noso1{
    display: flex;
    align-items: center;
    font-size:clamp(1.02rem, 5vw, 2.8rem);
    
}
.info-noso1{
    width: 100%;
    height: 400px;
    background: url("../../images/mv/v.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 7px;
    box-shadow: 5px 8px 8px gray;
 }
 .info-noso2{
    width: 100%;
    height: 400px;
    background: url("../../images/mv/m.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 7px;
    box-shadow: 5px 8px 8px gray;
 }
 .efecto{
    width: 100%;
    height: 100%;
    background: rgb(28, 28, 28, 0.4);
    padding: 0 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 7px;
 }
.ti-noso{
    padding: 20px 0;
    color: #e5e5e5;
    font-size: 2rem;
}
.info-noso1 p,
.info-noso2 p{
    font-size: 1.1rem;
    color: #e5e5e5;
}

.tres{
    background: #F2F2F2;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cuerpo3{
    width: 90%;
    display: grid;
    grid-template-columns: repeat(5,1fr);
    text-align: justify;
    padding: 50px 0;
    gap: 30px 10px;
    overflow: hidden;
    place-content: center;
    padding: 0 0 50px 20px;
}
.valores1{
    width: 100%;
    grid-column: 1/6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background: #A5CF8E; */
}
.valores1 p{
    font-size:clamp(1.02rem, 5vw, 1.3rem);
}
.ti-valores1{
    font-size:clamp(1.02rem, 5vw, 2.8rem);
    display: flex;
    align-items: center;
    margin: 30px 0 0 0;
}
.ti-valores{
    font-size: 1rem;
}
.info-valores{
    border-left: 1px solid black;
    padding: 30px;
}
.valores{
    display: flex;
    align-items: center;
    justify-content: left;
    width: 100%;
}
.tres2{
    background: rgb(230, 236, 240);
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cuerpo3-2{
    width: 90%;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    padding: 30px 0;
    overflow: hidden;
}
.logro--{
    grid-column: 1/5;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.logro{
    font-size:clamp(1.02rem, 5vw, 2.8rem);
}
.logro-- p{
    font-size: 1.3rem;
}
.img-tanques{
    background: url("../../images/Logros/tanques.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 7px;
    display: flex;
    width: 100%;
    height: 300px;
    transition: 1s ease;
}
.img-tanques:hover{
    background: url("../../images/Logros/tanques.jpg2.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.img-biocamas{
    background: url("../../images/Logros/biocama2.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 7px;
    display: flex;
    width: 100%;
    height: 300px;
    transition: 1s ease;
}
.img-biocamas:hover{
    background: url("../../images/Logros/biocamas.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.img-biogestores{
    background: url("../../images/Logros/biodegestores.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 7px;
    display: flex;
    width: 100%;
    height: 300px;
    transition: 1s ease;
}
.img-biogestores:hover{
    background: url("../../images/Logros/biodegestores2.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.img-baño{
    background: url("../../images/Logros/baño.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 7px;
    display: flex;
    width: 100%;
    height: 300px;
    transition: 1s ease;
}
.img-baño:hover{
    background: url("../../images/Logros/baño2.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.content{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 0 20px;
    cursor: pointer;
    border-radius: 7px;
    opacity: 0;
    transition: 1s ease-in-out;
}
.content:hover{
    background: rgb(28, 28, 28, 0.7);
    opacity: 1;
}
.ti-log{
    text-align: center;
    font-size: 1rem;
    font-weight: 100;
    padding: 15px 0 20px 0;
}
.li-log{
    text-align: left;
    display: flex;
    list-style: none;
    color: white;
    line-height: 30px;
}
b{
    padding: 0 10px 0 0;
}
.info-log{
    width: 100%;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
 }
 @media (max-width:1024px){
    .p-prese{
        font-size: 0.9rem;
    }
    .cuerpo3{
        grid-template-columns:repeat(3,1fr) ;
        height: auto;
    }
    .tres2{
        height: auto;
    }
    .valores1{
        grid-column: 1/4;
    }
    .logro--{
        grid-column: 1/3;
    }
    .ti-log{
        padding: 20px 0 10px 0;
        font-size: 1rem;
    }
    .li-log{
        font-size: 0.95rem;
    }
    .cuerpo3-2{
        grid-template-columns: repeat(2,1fr);
        padding: 30px 0;
    }
    .efecto p {
        font-size: 1rem;
    }
 }
 @media (max-width:769px){
    .ti-prese{
        font-size: 2.8rem;
        text-align: center;
        padding: 0 0 15px 0;
    }
    .cuerpo1{
        grid-template-columns: 1fr;
        width: 100%;
        height: 100%;
        place-items: center;
    }
    .info-prese{
        padding: 0;
    }
    .img-prese{
        margin:20px 0 20px 0 ;
        width: 90%;
        height: 500px;
        background-size: cover;
    }
    .cuerpo3{
        width: 90%;
        grid-template-columns: repeat(2,1fr);
        padding: 0;
    }
    .valores1{
        grid-column: 1/3;
    }   
    
    .logro--{
        grid-column: 1;
  
    }
    .cuerpo3-2{
        grid-template-columns: 1fr;
    }
    .defi{
        grid-template-columns: 1fr;
        row-gap: 0;
    }
    
    .nosotros1{
        grid-column: 1/2;
    }
    .cuerpo2{
        grid-template-columns: 1fr;
       width: 90%;
    }
 }
 @media (max-width:391px){
    .nosotros1 p,
    .valores1 p,
    .logro-- p{
        font-size: 1.02rem;
    }
 }
