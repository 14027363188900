

header {
    box-sizing: border-box;
    background:#F1F4E5;
    z-index: 90;
    position:fixed;
    width: 100%;
    min-height: 70px;
    padding: 0 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* overflow: hidden; */
}
header .logo{
    margin: 0;
    display: flex;
    align-items: center;
    max-height: 40px;
    width: 100%;
}
header ul{
    position: relative;
}
header ul li{
    position: relative;
    list-style: none;
    float: left;
    margin: 5px;
}
header ul li #ppp{
    flex-direction: column;
}
.a{
    height: 30px;
    color: black;
    font-size: 1rem;
    padding: 15px 18px;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.b{
    height: 30px;
    color: black;
    font-size: 1rem;
    padding: 15px 18px;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.p-1{
    color: black;
    font-size: 1rem;
    padding: 5px 18px;
    text-decoration: none;
    align-items: center;
    cursor: pointer;
    display: block;
    border-radius: 4px;
    /* width: 160px; */
}
.p-1:hover{
    background:rgb(221, 240, 192);
   color: green;
}
h5{
    height: 30px;
    color: black;
    font-weight: lighter;
    font-size: 1rem;
    padding: 15px 18px;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
header ul li .a:hover{
    color: green;
}
header ul #pp #ppp{
    position: absolute;
    background: #F1F4E5;
    left: -70px;
    width: 196px;
    display: none;
    padding: 10px;
    text-align: left;
    border-radius: 0 0 8px 8px;
}

header ul #pp:hover > #ppp {
    display: flex;
}

header ul .aa .aaa{
    position: fixed;
    background: #F1F4E5;
    left:7.5%;
    width: 85%;
    display: none;
    padding: 0 0 ;
    text-align: left;
    border-radius: 0 0 8px 8px;
}
header ul .aa:hover > .aaa{
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 10px;
    padding: 0 20px 10px 20px;
}
.li-titulo{
    width: 180px;
    font-weight:600;
    border-bottom: 1px solid black;
}
.sub-menus{
    padding: 5px 10px;
    font-weight: 600;
}
.aa .aaa ul {
    display: flex;
    flex-direction: column;
}
.aa .aaa ul li {
    margin: 5px;
}
.aa .aaa ul li a{
    color: black;
    text-decoration: none;
    border-radius: 4px;
    padding: 5px 10px;
    box-sizing: border-box;
    display: block;
}
.aa .aaa ul li a:hover{
   background:rgb(221, 240, 192);
   color: green;
}
svg{
    rotate: -90deg;
    position: relative;
    left: 8px;
}
.bi.bi-chevron-right{
    transition: transform 0.3s ease;
}
.bi.bi-chevron-right.rotated{
    transform: rotate(180deg);
}
@media (max-width:1137px){
    header{
        padding: 10px 20px;
    }
    header nav{
        position: absolute;
        overflow: auto;
        max-height: 800px;
        width: 100%;
        top: 66px;
        left: 0;
        background:#F1F4E5;
        display: none;
    }
    header.active nav{
        display: initial;
    }
    
    header nav ul li{
        width: 95%;
    }
    header nav ul #pp #ppp{
        width: 100%;
        position: relative;
        left: 0;
    }
    header nav ul .aa .aaa{
        width: 100%;
        position: relative;
        left: 0;

    }
    header ul .aa:hover > .aaa{
        grid-template-columns: repeat(1,1fr);
    }

    /* header ul li ul li ul{
        top: 0;
        left: 0;
    } */
    .submenu{
        top: auto;
    }
    header nav ul li:hover ul li{
        /* background:rgba(225, 225, 172, 0.229); */
        top: 0;
    }
    .menutoggle{
        position: relative;
        width: 25px;
        height: 25px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .menutoggle::before{
        content: '';
        position: absolute;
        width: 100%;
        height: 2.5px;
        background: black;
        transform: translateY(-12px);
        box-shadow: 0 9px black;
        top: 12px;
    }
    .menutoggle::after{
        content: '';
        position:absolute;
        width: 100%;
        height: 2.5px;
        background: black;
        transform: translateY(12px);
        top: 6px;
    }
    header.active .menutoggle::before{
        transform: rotate(45deg);
        top: 8px;
        box-shadow: 0 0 black;
         width: 100%;
        height: 2.5px;
    }
    header.active .menutoggle::after{
        transform: rotate(315deg);
        top: 7px;
    }
    .b{
        height: 30px;
        color: black;
        font-size: 1rem;
        padding: 15px 18px;
        text-decoration: none;
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        cursor: pointer;
    }
    .b p{
        padding-left: 10px;
    }
}

