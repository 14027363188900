.section-clientes{
    width: 100%;
    height: auto;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 70px 0 30px 0;
    overflow: hidden;
}
.socios--{
    width: 90%;
    display: grid;
    grid-template-columns: repeat(5,1fr);
    justify-content: center;
    align-items: flex-start;
    gap: 1.8rem;
    padding: 30px 0 40px;
    overflow: hidden;
}

.img-clientes{
    padding: 15px;
    width: 80%;
    height: 6rem; 
    background: white;
    border-radius: 5px;
    cursor: pointer;
}
.img-clientes:hover{
    transform: scale(0.95);
    transition: transform 0.2s;
    border: 1px solid black;
}
.titulo{
    padding: 40px 0;
    background: url("../../images/fondo.jpg");
    color: white;
    width: 100%;
    font-size: 1.5rem;
}
.asocidado{
    padding: 50px 0 20px 0;
    display: grid;
    gap: 1.8rem;
    grid-template-columns: repeat(2,1fr);
}
.img-asociado{
    padding: 20px 20px;
    width: 80%;
    height: 8rem; 
    background: white;
    border-radius: 5px;
    cursor: pointer;
}
.img-asociado:hover{
    transform: scale(1.1);
    border: 1px solid black;
}
@media (max-width:1024px){
    .socios--{
        grid-template-columns: repeat(3,1fr);
        width: 50%;
    }
}
@media (max-width:769px){
    .socios--,
    .asocidado{
        grid-template-columns: 1fr;
    }
}
