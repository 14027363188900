
.cuatro{
    height: auto;
    width: 100%;
    display: flex;
    justify-content:center;
    align-items: center;
    padding: 70px 0 0 0;
    overflow: hidden;
}
.present{
    padding: 50px;
    width: 100%;
    transition: opacity 0.1ms ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background: rgb(32, 32, 32); */
}
.present h1{
    font-size: 2.5rem;
}
.present p{
    font-size: 1.4rem;
    display: block;
    padding: 15px 0 0 0;  
}

.cinco{
    background:rgb(255, 255, 255);
    width: 100%;
    height:auto;
    display: flex;
    justify-content:center;
    align-items: center;
    overflow: hidden;
}
.cuerpo5{
    width: 90%;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    align-items:center;
    justify-content: space-around;  
    gap: 30px;
    padding: 50px 0;
    overflow: hidden;

}
.contenedor-perfiles1,
.contenedor-perfiles2{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    
}
.robles{
    width: 100%;
    height: 400px;
    background-image: url("../../images/equipo/nosotros.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    border-radius: 25px;
    box-shadow: 5px 8px 20px rgba(32, 32, 32, 0.4);
}
.robles:hover{
    transform: scale(0.9);
    transition: 0.8s ease; 
}
/* .renato{
    width: 100%;
    height: 400px;
    background-image: url("../../images/equipo/renato.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    border-radius: 25px;
    box-shadow: 5px 8px 20px rgba(32, 32, 32, 0.4);
}
.renato:hover{
    transform: scale(0.9);
    transition: 0.8s ease;
} */
.piero{
    width: 100%;
    height: 400px;
    background-image: url("../../images/equipo/piero.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    border-radius: 25px;
    box-shadow: 5px 8px 20px rgba(32, 32, 32, 0.4);
}
.piero:hover{
    transform: scale(0.9);
    transition: 0.8s ease;
}
.gian{
    width: 100%;
    height: 400px;
    background-image: url("../../images/equipo/gian.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    border-radius: 25px;
    box-shadow: 5px 8px 20px rgba(32, 32, 32, 0.4);
}
.gian:hover{
    transform: scale(0.9);
    transition: 0.8s ease;
}
.flavio{
    width: 100%;
    height: 400px;
    background-image: url("../../images/equipo/flavio.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    border-radius: 25px;
    box-shadow: 5px 8px 20px rgba(32, 32, 32, 0.4);
}
.flavio:hover{
    transform: scale(0.9);
    transition: 0.8s ease;
}
.almendra{
    width: 100%;
    height: 400px;
    background-image: url("../../images/equipo/almendra.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    border-radius: 25px;
    box-shadow: 5px 8px 20px rgba(32, 32, 32, 0.4);
}
.almendra:hover{
    transform: scale(0.9);
    transition: 0.8s ease;
}
.kiara-j{
    width: 100%;
    height: 400px;
    background-image: url("../../images/equipo/kiara-j.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    border-radius: 25px;
    box-shadow: 5px 8px 20px rgba(32, 32, 32, 0.4);
}
.kiara-j:hover{
    transform: scale(0.9);
    transition: 0.8s ease;
}
.kiara-c{
    width: 100%;
    height: 400px;
    background-image: url("../../images/equipo/kiara-c.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    border-radius: 25px;
    box-shadow: 5px 8px 20px rgba(32, 32, 32, 0.4);
}
.kiara-c:hover{
    transform: scale(0.9);
    transition: 0.8s ease;
}
.luis{
    width: 100%;
    height: 400px;
    background-image: url("../../images/equipo/luis.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    border-radius: 25px;
    box-shadow: 5px 8px 20px rgba(32, 32, 32, 0.4);
}
.luis:hover{
    transform: scale(0.9);
    transition: 0.8s ease;
}
/* .leonardo{
    width: 100%;
    height: 400px;
    background-image: url("../../images/equipo/leonardo.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    border-radius: 25px;
    box-shadow: 5px 8px 20px rgba(32, 32, 32, 0.4);
}
.leonardo:hover{
    transform: scale(0.9);
    transition: 0.8s ease;
} */
.karolina{
    width: 100%;
    height: 400px;
    background-image: url("../../images/equipo/karolina.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    border-radius: 25px;
    box-shadow: 5px 8px 20px rgba(32, 32, 32, 0.4);
}
.karolina:hover{
    transform: scale(0.9);
    transition: 0.8s ease;
}
.jahyr{
    width: 100%;
    height: 400px;
    background-image: url("../../images/equipo/jahyr.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    border-radius: 25px;
    box-shadow: 5px 8px 20px rgba(32, 32, 32, 0.4);
}
.jahyr:hover{
    transform: scale(0.9);
    transition: 0.8s ease;
}
.juan{
    width: 100%;
    height: 400px;
    background-image: url("../../images/equipo/juan.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    border-radius: 25px;
    box-shadow: 5px 8px 20px rgba(32, 32, 32, 0.4);
}
.juan:hover{
    transform: scale(0.9);
    transition: 0.8s ease;
}
.raamses{
    width: 100%;
    height: 400px;
    background-image: url("../../images/equipo/raamses.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    border-radius: 25px;
    box-shadow: 5px 8px 20px rgba(32, 32, 32, 0.4);
}
.raamses:hover{
    transform: scale(0.9);
    transition: 0.8s ease;
}
.junior{
    width: 100%;
    height: 400px;
    background-image: url("../../images/equipo/junior.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    border-radius: 25px;
    box-shadow: 5px 8px 20px rgba(32, 32, 32, 0.4);
}
.junior:hover{
    transform: scale(0.9);
    transition: 0.8s ease;
}
.mauricio{
    width: 100%;
    height: 400px;
    background-image: url("../../images/equipo/mauricio.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    border-radius: 25px;
    box-shadow: 5px 8px 20px rgba(32, 32, 32, 0.4);
}
.mauricio:hover{
    transform: scale(0.9);
    transition: 0.8s ease;
}
.jorge{
    width: 100%;
    height: 400px;
    background-image: url("../../images/equipo/jorge.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    border-radius: 25px;
    box-shadow: 5px 8px 20px rgba(32, 32, 32, 0.4);
}
.jorge:hover{
    transform: scale(0.9);
    transition: 0.8s ease;
}

.angelo{
    width: 100%;
    height: 400px;
    background-image: url("../../images/equipo/angelo.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    border-radius: 25px;
    box-shadow: 5px 8px 20px rgba(32, 32, 32, 0.4);
}
.angelo:hover{
    transform: scale(0.9);
    transition: 0.8s ease;
}

.yoner{
    width: 100%;
    height: 400px;
    background-image: url("../../images/equipo/yoner.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    border-radius: 25px;
    box-shadow: 5px 8px 20px rgba(32, 32, 32, 0.4);
}
.yoner:hover{
    transform: scale(0.9);
    transition: 0.8s ease;
}

.gustavo{
    width: 100%;
    height: 400px;
    background-image: url("../../images/equipo/gustavo.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    border-radius: 25px;
    box-shadow: 5px 8px 20px rgba(32, 32, 32, 0.4);
}
.gustavo:hover{
    transform: scale(0.9);
    transition: 0.8s ease;
}
.txt-ul{
    opacity: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    color: white;
    cursor: pointer;
    line-height: 21px;
    transition: 1s ease-in-out;
    border-radius: 25px;
    
}
.txt-ul:hover{
    opacity: 1;
    background: rgb(28, 28, 28, 0.8);
}
.ti-equi{
    font-size: 1.3rem;
    padding: 15px 0 0 0;
}
.p-equi{
    font-size: 1.2rem;
}
.li{
    list-style: none;
    display: flex;
    text-align: left;
}
.ul{
    font-size: 0.9rem;
}
 .li svg {
    rotate: 0deg;

}
@media (max-width:1024px){
    .ti-equi{
        font-size:2rem;
    }
    .p-equi{
        font-size: 1.2rem;
    }
    .cuatro,
    .cinco{
        height: auto;
    }
    .cuerpo4{
        padding: 30px 0;
       
    }
    .img-equi{
       width: 300px;
        height: 350px;
    }
    .cuerpo5{
        padding: 30px 0;
        grid-template-columns: repeat(2,1fr);
    }
}
@media (max-width:769px) {

    .cuerpo4,
    .cuerpo5 {
        grid-template-columns: 1fr;
    }
    .robles,
    .almendra,
    .flavio,
    .karolina,
    .kiara-c,
    .kiara-j,
    .luis,
    .leonardo,
    .juan,
    .junior,
    .raamses,
    .piero,
    .renato,
    .jahyr,
    .gian{
        height: 500px;
    }
}
@media (max-width:391px) {

    .cuerpo4,
    .cuerpo5 {
        grid-template-columns: 1fr;
    }
    .robles,
    .almendra,
    .flavio,
    .karolina,
    .kiara-c,
    .kiara-j,
    .luis,
    .leonardo,
    .juan,
    .junior,
    .raamses,
    .piero,
    .renato,
    .jahyr,
    .gian{
        height: 400px;
    }
}