
/* PORTADA */
#portada{
    max-width: 100%;
    padding: 70px 0 30px 0;
    overflow: hidden;
}
.slides{
    width: 500%;
    height: 720px;
    display: flex;
    overflow: hidden;
}
.slides input{
    display: none;
}
.slide{
    width: 20%;
    transition:all 1s ease-in;
}
.slide img{
    width: 100%;
    height: 720px;
    /* opacity: 0.65; */
}

.manual{
    position:absolute;
    width: 100%;
    margin-top: -90px;
    display: flex;
    justify-content: center;
    overflow: hidden;
}
.manual-btn{
    border: 2px solid #e5e5e5;
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
    transition: 1s;
}
.manual-btn:not(:last-child){
    margin-right: 40px;
}
.manual-btn:hover{
    background: #e5e5e5;
}
#radio1:checked ~ .first{
    margin-left: 0;
}
#radio2:checked ~ .first{
    margin-left: -20%;
}
#radio3:checked ~ .first{
    margin-left: -40%;
}
#radio4:checked ~ .first{
    margin-left: -60%;
}
.auto{
    position: absolute;
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 630px; 
    overflow: hidden;
}
.auto div{
    border: 2px solid #e5e5e5;
    padding: 5px;
    border-radius: 10px;
    transition: 1s;
}
.auto div:not(:last-child){
    margin-right: 40px;
}
#radio1:checked ~ .auto .auto-btn1,
#radio2:checked ~ .auto .auto-btn2,
#radio3:checked ~ .auto .auto-btn3,
#radio4:checked ~ .auto .auto-btn4{
    background: #e5e5e5;
}
.fb{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
}
#cuerpo{
    width: 90%;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    place-content: center;
    gap: 20px;
    overflow: hidden;
}
#pag-fb{
    width: 500px;
    height: 550px;
}
#yt{
    width: 820px;
    height: 550px;
}

/* NOOTICIAS */

.noticias{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0 80px 0;
}
.titu{
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
}
.titu h1{
    font-size: clamp(1.02rem, 5vw, 2.8rem);
    margin-bottom: 20px;
}
#cuerpo{
    display: grid;
    width: 90%;
    grid-template-columns: repeat(4, 1fr);
    padding: 20px;
    gap: 25px;
}
.img-portada{
    width: 100%;
    height: 180px;
    border-radius: 8px 8px 0 0;
}

.itens{
    position: relative;
    width: 100%;
    height: 410px;
    background: rgba(233, 233, 233, 0.57);
    border-radius: 8px;
    cursor: pointer;
    overflow: hidden;
}
.itens:hover{
    transform: translateY(-1rem);
    background: rgb(250, 250, 250);
    box-shadow: 0 8px 22px rgba(0, 0, 0, .15);
    transition: transform 0.4s ease;
}
.url{
    text-decoration: none;
    color: rgba(28, 28, 28, 0.933);
}
.url h1{
    font-weight: 600;
    font-size: 1.1rem;
    margin: 10px 15px;
    text-align: center;
    word-wrap: break-word;
}
.itens p{
    margin: 15px 20px;
    font-size: 1rem;
    text-align: justify;
    word-break: break-all;
}
.sin-pu{
    grid-column: 1/5;
    font-size: 1.1rem;
}
@media (max-width:1024px){
    #cuerpo{
        grid-template-columns: repeat(2,1fr);
        gap: 20px;
        place-items: center;
    }
    #pag-fb{
        width: 380px;
    }
    #yt{
        aspect-ratio: 4/4;
    }
}
@media (max-width:769px){
    .slides{
        height: 400px;
    }
    .slides .img-slide{
        height: 400px;
    }
    .auto{
        margin-top: 340px;
    }
    .manual{
        margin-top: -60px;
    }
    #portada{
        padding: 70px 0 0 0;
    }
    #cuerpo{
        grid-template-columns: 1fr;
        gap: 40px;
        place-items: center;
    }
    #pag-fb{
        width: 400px;
    }
    #yt{
        width: 320px;
        height: 300px;
    }
}
@media (max-width:391px){
    .slides{
        height: 400px;
    }
    .slides img{
        height: 400px;
    }
    .auto{
        margin-top: 340px;
    }
    .manual{
        margin-top: -60px;
    }
    #portada{
        padding: 70px 0 0 0;
    }
    #cuerpo{
        grid-template-columns: 1fr;
        gap: 20px;
        place-items: center;
    }
    #pag-fb{
        width: 350px;
        height: 380px;
    }
    #yt{
        width: 100%;
        height: 300px;
    }
}