.bañosdignos{
    width: 100%;
    height: auto;
    padding: 70px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.cuerpo-bañosdignos{
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.titulo-bañosdignos{
    padding: 40px 0;
    background: url("../../images/fondo.jpg");
    color: white;
    width: 100%;
    font-size: 1.5rem;
}
.list-bañosdignos{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 20px;
}
.info-7{
    text-align: left;
    border: 1px solid black;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    height: auto;
}
.bañosdignos-ti{
    font-size: 1.1rem;
    font-weight: 800;
    padding: 0 0 18px 0;
    text-align: justify;
}
.bañosdignos-ti2{
    font-size: 1rem;
    font-weight: 100;
    padding: 0 0 15px 0;
    text-align: justify;
}
.info-7 li{
    text-align: justify;
    margin: 0 0 0 20px;
}
.div-bañosdignos{
    display: flex;
    flex-direction: column;
    /* justify-content: space-evenly; */
    align-items: center;
}
.bañosdignos-img{
    width: 100%;
    height: auto;
    border-radius: 8px;
    border: 1px solid black;
}
.bañosdignos-img2{
    position: relative;
    width: 100%;
    height: auto;
    border-radius: 8px;
    border: 1px solid black;
    margin: 20px 0 0 0 ;
}

@media (max-width:1024px){
    .list-bañosdignos{
        grid-template-columns: 1fr;
    }
}
@media (max-width:391px){
    .bañosdignos-ti{
        font-size: 0.9rem;
    }
    .bañosdignos-ti2{
        font-size: 0.9rem;
    }
    .info li{
        font-size: 0.9rem;
    }
}