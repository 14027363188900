
footer{
    display: grid;
    /* gap: 20px; */
    grid-template-columns: repeat(3,1fr);
    background-color: rgb(0, 0, 0, 0.91);
    color: white;
    height:auto;
    padding: 12px 80px 0 80px;
    place-items: center;
    width: auto;
    align-items: flex-start;
    background: linear-gradient(rgba(0,0,0,0.5));
    overflow: hidden;
}
.end{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    color: white;
    background-color: rgb(0, 0, 0, 0.89);
    font-size: 7px;
    border-top: 1px solid rgba(255, 255, 255, 0.211);
    box-shadow: 1px 10px 20px rgba(102, 102, 102, 0.736);
    overflow: hidden;
}
.end b{
    cursor: pointer;
}
.end b:hover{
    color: #25d366;
}
/* FOOTER 1 */
/* TXT1 FOOTER 1 */
.footer-1{
    display:grid;
    grid-template-rows: repeat(2,1fr);
    align-items: center;
    justify-content: center;
    height: 250px;
    padding: 20px 0 0 0;
    overflow: hidden;
}
.logo-footer{
    /* margin: 80px 0 0 0; */
    width: 85px;
}
.txt1-footer-1{ 
    display: flex; 
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
}
.ti2-1{
    font-size: 1.5em;
    max-width: 100%;
}
.ti2-inter{
    max-width: 100%;
    padding: 8px 0 10px 0;
    font-size: 1.2rem;
}
.redes{
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    
}
.redes a{
    color: white;
    padding: 0 15px;
    font-size: 22px;
}
.redes a:hover{
    color: #A5CF8E; 
}
/* FOOTER 2 */ /* FOOTER 3 */ /* FOOTER 4 */
.footer-2,
.footer-4{
    padding: 20px 0 15px 0;
    height: 100%;
    overflow: hidden;
}
.footer-3{
    padding: 25px 0 0 0;
    overflow: hidden;
}
.footer-2{
    text-align: left;
    /* width: 100%; */
}
.footer-2,
.footer-4{
    text-align: left;
}
.footer-4{
    width: 100%;
    height: 100%;
}
#map{
    width: 100%;
}
.txt1-footer-2{
    font-size: 1.2em;
    max-width: 100%;
    padding-bottom: 10px;
}

.txt2-footer-2{
    line-height:35px;
    list-style: none;
}
.txt222-footer-2{
    text-decoration: none;
    color: white;
    cursor: pointer;
    max-width: 100%;
    font-size: 1em;
}
.txt222-footer-2:hover{
    color: #A5CF8E;
}
.flotante{
    display: flex;
    flex-direction: column;
    position: fixed;
    cursor: pointer;
    bottom: 0;
    right: 0;
    margin: 30px 30px;
}
.bi-whatsapp, .bi-chat-dots,#scrollButton, .bi-file-earmark-text{
    rotate:0deg;
    text-decoration: none;
    border-radius: 50%;
    padding: 10px;  
    color: rgb(255, 255, 255);
    background: #25d366;
}

.a_svg{
    position: relative;
}
.hover_info_icon_late p{
    background: white;
    color: black;
    padding: 8px;
    border-radius: 8px;
    position: relative;
    border: 2px solid #ddd;
    box-shadow: 0 4px 8px rgba(54, 54, 54, 0.15);
}
.hover_info_icon_late{
    display: none;
    position: absolute;
    right: 50px;
    top: 0;
}
.a_svg:hover> .hover_info_icon_late{
    display: flex;
}
.hover_info_icon_late p::before{
    content: '';
    width: 0;
    height: 0;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    right: -21px;
    border-top: 12px solid transparent;
    border-left: 9px solid #ddd;
    border-right: 12px solid transparent;
    border-bottom: 12px solid transparent;
}
.hover_info_icon_late p::after{
    content: '';
    width: 0;
    height: 0;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    right: -21px;
    border-top: 14px solid transparent;
    border-left: 10px solid white;
    border-right: 14px solid transparent;
    border-bottom: 14px solid transparent;
}
.bi-chat-dots{
    background: #2c4b9e;
}
.bi-file-earmark-text{
    background: #920b28;
}
#scrollButton{
    rotate:-90deg;
    background:rgb(227, 180, 72);
    animation: pee 0.3s;
    display: none;
}
@keyframes pee{
    0% {
        opacity:0;
    }
    50%{
        opacity:0.5;
    }
    100% {
        opacity:1;
    }
}
.ti1-{
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 20px 0 10px 0 ;
    font-size: 1.2rem;
    margin: auto;
    font-weight: 100;
}
.pa1 {
    display: flex;
    padding: 0 2px;
    align-items: center;
    justify-content: left;
}
.p1{
    padding: 0 20px 0 0;
}
#contacto{
    display: flex;
    flex-direction: column;
}
#contacto a{
    color: white;
    text-decoration: none;
}
#contacto h2{
    font-weight: 100;
    font-size: 1.2rem;
    padding-bottom: 15px;
}
#contacto  p{
    line-height: 30px;
}
#contacto i{
    padding: 0 15px 0 0;
    
}
.bi-youtube::before {
    font-size: 1.9rem;
    padding-top: 3px;
}
@media (max-width:1024px) {
    footer {
        grid-template-columns: repeat(3, 1fr);
        padding: 15px 25px;
    }
    .footer-1 {
        width: 100%;
    }

    .ti2-inter {
        padding: 15px 0;
    }

    .redes {
        top: 50px;
        height: 100%;
    }

    .footer-4 {
        padding: 0 0;
    }
}
@media (max-width:769px) {
    footer{
        grid-template-columns: 1fr;
        padding: 30px 20px;
    }
    .footer-1{
        grid-column: 1/3;
        height: 250px;  
    }
    .redes{
        height: auto;
    }
    .footer-4{
        grid-column: 1/3;
    }
    .txt-end{
        font-size:0.8rem;
        padding: 0 10px 0 10px;
    }
    .---,#contacto{
      padding-left: 20px;
    }
}
@media (max-width:391px) {
    .---,#contacto{
        padding-left: 20px;
      }

}